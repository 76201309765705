var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portlet',{attrs:{"color":_vm.criticality.color}},[_c('template',{slot:"title"},[_vm._v(" "+_vm._s(_vm.risk.position)+" - "+_vm._s(_vm.risk.character !== null ? _vm.risk.character.label : '')+" (Criticité "+_vm._s(_vm.criticality.quotation)+" : "+_vm._s(_vm.criticality.value)+") ")]),_c('template',{slot:"actions"},[_vm._t("actions")],2),(_vm.selectMode)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5"},[_c('portlet',{attrs:{"color":"light-grey","title":"Affectations"}},[(_vm.risk.structures.length > 0)?_c('ul',_vm._l((_vm.risk.structures),function(structure,key){return _c('li',{key:key},[_vm._v(" "+_vm._s(structure.path)+" ")])}),0):_c('p',[_vm._v(" Aucune affectation ")])])],1),_c('div',{staticClass:"col-md-7",staticStyle:{"white-space":"pre-wrap"}},[_vm._v(" description : "),_c('br'),_vm._v(" "+_vm._s(_vm.risk.description)+" ")])]):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('portlet',{attrs:{"color":"light-grey","title":"Affectations"}},[(_vm.risk.structures.length > 0)?_c('ul',_vm._l((_vm.risk.structures),function(structure,key){return _c('li',{key:key},[_vm._v(" "+_vm._s(structure.path)+" ")])}),0):_c('p',[_vm._v(" Aucune affectation ")])])],1),_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[(_vm.editable)?_c('el-select',{attrs:{"placeholder":"Fréquence"},model:{value:(_vm.editFrequency),callback:function ($$v) {_vm.editFrequency=$$v},expression:"editFrequency"}},_vm._l((_vm.frequencies),function(ref){
var key = ref[0];
var value = ref[1];
return _c('el-option',{key:key,attrs:{"label":value,"value":key}})}),1):_c('span',[_vm._v(_vm._s(_vm.frequencies.get(_vm.risk.frequency) || 'n/c'))])],1),_c('div',{staticClass:"col-md-4"},[(_vm.editable)?_c('el-select',{attrs:{"placeholder":"Gravité"},model:{value:(_vm.editSeriousness),callback:function ($$v) {_vm.editSeriousness=$$v},expression:"editSeriousness"}},_vm._l((_vm.seriousness),function(ref){
var key = ref[0];
var value = ref[1];
return _c('el-option',{key:key,attrs:{"label":value,"value":key}})}),1):_c('span',[_vm._v(_vm._s(_vm.seriousness.get(_vm.risk.seriousness) || 'n/c'))])],1),_c('div',{staticClass:"col-md-4"},[(_vm.editable)?_c('el-select',{attrs:{"placeholder":"Maîtrise"},model:{value:(_vm.editMastery),callback:function ($$v) {_vm.editMastery=$$v},expression:"editMastery"}},_vm._l((_vm.masters),function(ref){
var key = ref[0];
var value = ref[1];
return _c('el-option',{key:key,attrs:{"label":value,"value":key}})}),1):_c('span',[_vm._v(_vm._s(_vm.masters.get(_vm.risk.mastery) || 'n/c'))])],1)]),(_vm.editable)?_c('div',{staticClass:"row"},[(_vm.risk.frequency === null || _vm.risk.seriousness === null || _vm.risk.mastery === null)?_c('div',{staticClass:"col-md-12 information-quotation-col"},[_c('div',{staticClass:"information-quotation",staticStyle:{"display":"block"}},[_c('font-awesome-icon',{attrs:{"icon":"exclamation"}}),_c('i',[_vm._v("Veuillez saisir les trois critères pour avoir un aperçu de la criticité.")])],1)]):_vm._e()]):_vm._e(),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 risque-quotation-desc"},[_c('p',[_vm._v(" "+_vm._s(_vm.risk.description)+" ")])]),_c('div',{staticClass:"col-md-12"},[_c('p',{staticClass:"bold accordion",on:{"click":function($event){_vm.showOrganizationalArrangements = !_vm.showOrganizationalArrangements}}},[_vm._v(" Prévention organisationnelle existante")]),_c('transition',{attrs:{"name":"slideCotation"}},[(_vm.showOrganizationalArrangements)?_c('p',{staticClass:"accordion-target",domProps:{"innerHTML":_vm._s(_vm.organizationalArrangements)}}):_vm._e()]),_c('p',{staticClass:"bold accordion",on:{"click":function($event){_vm.showCollectiveArrangements = !_vm.showCollectiveArrangements}}},[_vm._v(" Prévention collective existante ")]),_c('transition',{attrs:{"name":"slideCotation"}},[(_vm.showCollectiveArrangements)?_c('p',{staticClass:"accordion-target",domProps:{"innerHTML":_vm._s(_vm.collectiveArrangements)}}):_vm._e()]),_c('p',{staticClass:"bold accordion",on:{"click":function($event){_vm.showIndividualArrangements = !_vm.showIndividualArrangements}}},[_vm._v(" Prévention individuelle existante ")]),_c('transition',{attrs:{"name":"slideCotation"}},[(_vm.showIndividualArrangements)?_c('p',{staticClass:"accordion-target",domProps:{"innerHTML":_vm._s(_vm.individualArrangements)}}):_vm._e()])],1)])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }