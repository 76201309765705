

































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import StructureFilter from '@/components/form/StructureFilter.vue';
import FilterComponent from '@/components/form/FilterComponent';

import RiskCharacter from '@models/proRisk/RiskCharacter';
import { QuotationEnumName } from '@modules/professional-risk/types/enum';

const riskCharacterRepository = namespace('repositories/riskCharacter');

@Component({
  components: { StructureFilter },
})
export default class ProRiskQuotationFilter extends FilterComponent {
  @riskCharacterRepository.Getter('getAll')
  riskCharacters!: RiskCharacter[];

  filters = {
    riskCharacter: '',
    affectation: '',
    criticality: '',
  };

  criticalityEnum = QuotationEnumName;
}
