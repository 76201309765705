

































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { lazyInject, SERVICE_IDENTIFIER as CORE_SERVICE } from '@/ioc/container';
import { FormComponent } from '@/types/v-form';
import { RiskServiceInterface } from '@modules/professional-risk/services/RiskServiceInterface';
import SERVICE_IDENTIFIER from '@modules/professional-risk/ioc/identifiers';

import { PaginatedData } from '@/types/paginatedData';
import { ActionInterface, HelpActionInterface } from '@/types/breadcrumb';

import Layout from '@/layouts/LayoutDefault.vue';
import ProRiskForm from '@modules/professional-risk/components/form/ProRiskForm.vue';
import ProRiskQuotationCard from '@modules/professional-risk/components/ProRiskQuotationCard.vue';

import Risk from '@models/proRisk/Risk';
import ProFolder from '@models/proRisk/ProFolder';
import ProRiskQuotationFilter
  from '@modules/professional-risk/components/form/ProRiskQuotationFilter.vue';
import RequestSaveForm from '@/utils/RequestSaveForm';
import PreventNav from '@/utils/PreventNav';

const riskProModule = namespace('riskPro');
const riskRepository = namespace('repositories/risk');

@Component({
  components: {
    Layout,
    ProRiskForm,
    ProRiskQuotationCard,
    ProRiskQuotationFilter,
  },
})
@PreventNav({
  preventMsg: 'Vous avez des données modifiées non sauvegardées. Voulez-vous vraiment quitter la page ?',
})
export default class ListingView extends Vue {
  @lazyInject(SERVICE_IDENTIFIER.RiskServiceInterface)
  riskService!: RiskServiceInterface;

  @riskProModule.Getter('getActiveProFolder')
  proFolder!: ProFolder | null;

  @riskRepository.Action('search')
  fetchRisks!: (payload: { folder: ProFolder, filters: any }) => Promise<PaginatedData<Risk>>;

  @riskRepository.Getter('getAll')
  risks!: Risk[];

  @riskRepository.Action('delete')
  deleteRisk!: (id: number) => void;

  @riskRepository.Action('deleteAll')
  deleteRisks!: () => Promise<void>;

  isMounted: boolean = false;
  showUpdateRiskModal: boolean = false;
  loading: boolean = false;
  selectedRisk: Risk | null = null;

  risk: Risk | null = null;

  colors: Record<number, string> = {};
  totalResult = 0;

  filters: any = {
    riskCharacter: '',
    criticality: '',
    job: '',
    unit: '',
    limit: 10,
    page: 1,
  };

  helpMessage: string = '';

  $refs!: {
    quotationForm: FormComponent[]
  };

  get actions(): Array<ActionInterface> {
    return this.isGranted('pro-risks-cotation', 2) ? [
      {
        label: 'Sauvegarder les risques',
        handler: this.handleSave,
      },
    ] : [];
  }

  get helpAction(): HelpActionInterface | null {
    return this.helpMessage ? {
      content: this.helpMessage,
    } : null;
  }

  get isModify(): boolean {
    if (!this.isMounted) {
      return false;
    }

    return this.$refs.quotationForm ? this.$refs.quotationForm.reduce((modify, next) => modify || next.isModify, false as boolean) : false;
  }

  @Watch('filters', { immediate: true, deep: true })
  async onFilterChange() {
    if (this.proFolder && !this.loading) {
      this.loading = true;
      if (this.isModify) {
        this.$confirm('Vous avez des données modifiées non sauvegardées. Voulez-vous vraiment quitter la page ?').then(() => {
          this.confirmFetchRisks();
          this.loading = false;
        });
      } else {
        await this.confirmFetchRisks();
        this.loading = false;
      }
    }
  }

  async confirmFetchRisks() {
    if (this.proFolder) {
      const data = await this.fetchRisks({ folder: this.proFolder, filters: this.filters });
      this.totalResult = data.meta.total;
    }
  }

  async created() {
    const page = await this.pageService.get('cotation');
    this.helpMessage = page.help;
  }

  color(risk: Risk) {
    return this.riskService.criticality(risk).color;
  }

  editRisk(risk: Risk) {
    this.selectedRisk = risk;
    this.showUpdateRiskModal = true;
  }

  @RequestSaveForm({
    validMsg: 'Sauvegarde effectuée.',
    errorMsg: 'Erreur lors de la sauvegarde de vos cotations.',
  })
  async handleSave() {
    const vms = this.$refs.quotationForm;

    const promises: Promise<any>[] = [];
    vms.forEach((vm) => {
      promises.push(vm.valid());
    });

    return Promise.all(promises);
  }

  handleDeleteBtn(risk: Risk) {
    this.$confirm('Confirmer la suppression du risque.').then((result) => {
      if (result === 'confirm') {
        this.loading = true;
        this.handleDelete(risk.id);
        this.loading = false;
      }
    });
  }

  @RequestSaveForm({
    validMsg: 'Suppression effectuée.',
    errorMsg: 'Erreur lors de la suppression de votre risque.',
  })
  handleDelete(id: number) {
    return this.deleteRisk(id);
  }

  mounted() {
    this.isMounted = true;
  }

  beforeDestroy() {
    this.deleteRisks();
  }
}
