




























































































































import _ from 'lodash';
import {
  Vue, Component, Prop, Watch, PropSync,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { lazyInject } from '@/ioc/container';
import { FormComponent } from '@/types/v-form';
import SERVICE_IDENTIFIER from '@modules/professional-risk/ioc/identifiers';
import { RiskServiceInterface } from '@modules/professional-risk/services/RiskServiceInterface';
import { Criticality, CRITICALITY_UNDEFINED } from '@modules/professional-risk/types/risk';
import {
  FrequenciesEnumName,
  MastersEnumName,
  SeriousnessEnumName,
} from '@modules/professional-risk/types/enum';

import Risk from '@models/proRisk/Risk';

const riskProRepository = namespace('repositories/risk');

@Component
export default class ProRiskQuotationCard extends Vue implements FormComponent {
  @Prop({})
  value!: Risk;

  @Prop({ type: Boolean, default: false })
  selectMode!: boolean;

  @PropSync('color')
  colorSync!: string;

  @Prop({ type: Boolean, default: true })
  editable!: boolean;

  @lazyInject(SERVICE_IDENTIFIER.RiskServiceInterface)
  riskService!: RiskServiceInterface;

  @riskProRepository.Action('updateQuotation')
  save!: (risk: Risk) => Promise<void>;

  risk: Risk | null = null;
  riskColor: Risk | null = null;

  showOrganizationalArrangements: boolean = false;
  showIndividualArrangements: boolean = false;
  showCollectiveArrangements: boolean = false;

  frequencies = FrequenciesEnumName;
  seriousness = SeriousnessEnumName;
  masters = MastersEnumName;

  editFrequency: number | null = null;
  editSeriousness: number | null = null;
  editMastery: number | null = null;

  get criticality(): Criticality {
    this.riskColor = _.cloneDeep(this.risk);
    if (this.riskColor) {
      this.riskColor.frequency = this.editFrequency;
      this.riskColor.seriousness = this.editSeriousness;
      this.riskColor.mastery = this.editMastery;
    }
    return this.riskColor ? this.riskService.criticality(this.riskColor) : CRITICALITY_UNDEFINED;
  }

  get organizationalArrangements() {
    return this.risk && this.risk.organizationalArrangements ? this.risk.organizationalArrangements : '<i>(Non renseigné)</i>';
  }

  get collectiveArrangements() {
    return this.risk && this.risk.collectiveArrangements ? this.risk.collectiveArrangements : '<i>(Non renseigné)</i>';
  }

  get individualArrangements() {
    return this.risk && this.risk.individualArrangements ? this.risk.individualArrangements : '<i>(Non renseigné)</i>';
  }

  get isModify() {
    return this.risk !== null && (this.editFrequency !== this.value.frequency
      || this.editSeriousness !== this.value.seriousness
      || this.editMastery !== this.value.mastery);
  }

  @Watch('value', { immediate: true })
  onValueChange() {
    this.risk = _.cloneDeep(this.value);

    this.editFrequency = this.editFrequency === null ? this.risk.frequency : this.editFrequency;
    this.editSeriousness = this.editSeriousness === null ? this.risk.seriousness : this.editSeriousness;
    this.editMastery = this.editMastery === null ? this.risk.mastery : this.editMastery;
  }

  @Watch('criticality')
  onCriticalityChange() {
    this.colorSync = this.criticality.color;
  }

  @Watch('editFrequency')
  onEditFrequencyChange() {
    this.colorSync = this.criticality.color;
  }

  @Watch('editSeriousness')
  onEditSeriousnessChange() {
    this.colorSync = this.criticality.color;
  }

  @Watch('editMastery')
  onEditMasterChange() {
    this.colorSync = this.criticality.color;
  }

  async valid(): Promise<void> {
    if (this.risk !== null && this.isModify) {
      this.risk.frequency = this.editFrequency;
      this.risk.seriousness = this.editSeriousness;
      this.risk.mastery = this.editMastery;
      return this.save(this.risk);
    }

    return Promise.resolve();
  }
}
